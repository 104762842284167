define("discourse/plugins/chat/discourse/components/chat-channel-archive-status", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "@ember/template", "@ember/utils", "discourse/components/d-button", "discourse/helpers/concat-class", "discourse/lib/ajax-error", "discourse/lib/get-url", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _template, _utils, _dButton, _concatClass, _ajaxError, _getUrl, _discourseI18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatChannelArchiveStatus extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "chatApi", [_service.service]))();
    #chatApi = (() => (dt7948.i(this, "chatApi"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    get shouldRender() {
      return this.currentUser.admin && (0, _utils.isPresent)(this.args.channel.archive);
    }
    get channelArchiveFailedMessage() {
      const archive = this.args.channel.archive;
      const translationKey = !archive.topicId ? "chat.channel_status.archive_failed_no_topic" : "chat.channel_status.archive_failed";
      return (0, _template.htmlSafe)((0, _discourseI18n.i18n)(translationKey, {
        completed: archive.messages,
        total: archive.totalMessages,
        topic_url: this.topicUrl
      }));
    }
    get channelArchiveCompletedMessage() {
      return (0, _template.htmlSafe)((0, _discourseI18n.i18n)("chat.channel_status.archive_completed", {
        topic_url: this.topicUrl
      }));
    }
    retryArchive() {
      return this.chatApi.createChannelArchive(this.args.channel.id).catch(_ajaxError.popupAjaxError);
    }
    static #_3 = (() => dt7948.n(this.prototype, "retryArchive", [_object.action]))();
    get topicUrl() {
      if (!this.args.channel.archive.topicId) {
        return "";
      }
      return (0, _getUrl.default)(`/t/-/${this.args.channel.archive.topicId}`);
    }
    static #_4 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.shouldRender}}
          {{#if @channel.archive.failed}}
            <div
              class={{concatClass
                "alert alert-warn chat-channel-retry-archive"
                @channel.status
              }}
            >
              <div class="chat-channel-archive-failed-message">
                {{this.channelArchiveFailedMessage}}
              </div>
    
              <div class="chat-channel-archive-failed-retry">
                <DButton
                  @action={{this.retryArchive}}
                  @label="chat.channel_archive.retry"
                />
              </div>
            </div>
          {{else if @channel.archive.completed}}
            <div
              class={{concatClass "chat-channel-archive-status" @channel.status}}
            >
              {{this.channelArchiveCompletedMessage}}
            </div>
          {{/if}}
        {{/if}}
      
    */
    {
      "id": "TKSJISDL",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"shouldRender\"]],[[[41,[30,1,[\"archive\",\"failed\"]],[[[1,\"        \"],[10,0],[15,0,[28,[32,0],[\"alert alert-warn chat-channel-retry-archive\",[30,1,[\"status\"]]],null]],[12],[1,\"\\n          \"],[10,0],[14,0,\"chat-channel-archive-failed-message\"],[12],[1,\"\\n            \"],[1,[30,0,[\"channelArchiveFailedMessage\"]]],[1,\"\\n          \"],[13],[1,\"\\n\\n          \"],[10,0],[14,0,\"chat-channel-archive-failed-retry\"],[12],[1,\"\\n            \"],[8,[32,1],null,[[\"@action\",\"@label\"],[[30,0,[\"retryArchive\"]],\"chat.channel_archive.retry\"]],null],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],[[[41,[30,1,[\"archive\",\"completed\"]],[[[1,\"        \"],[10,0],[15,0,[28,[32,0],[\"chat-channel-archive-status\",[30,1,[\"status\"]]],null]],[12],[1,\"\\n          \"],[1,[30,0,[\"channelArchiveCompletedMessage\"]]],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[]],null]],[]]]],[]],null],[1,\"  \"]],[\"@channel\"],false,[\"if\"]]",
      "moduleName": "/home/discourse/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-channel-archive-status.js",
      "scope": () => [_concatClass.default, _dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatChannelArchiveStatus;
});