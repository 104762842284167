define("discourse/plugins/chat/discourse/components/chat/routes/threads", ["exports", "@glimmer/component", "@ember/service", "discourse-i18n", "discourse/plugins/chat/discourse/components/chat/navbar", "discourse/plugins/chat/discourse/components/user-threads", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _discourseI18n, _navbar, _userThreads, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatRoutesThreads extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="c-routes --threads">
          <Navbar as |navbar|>
            <navbar.Title @title={{i18n "chat.my_threads.title"}} />
    
            <navbar.Actions as |action|>
              <action.OpenDrawerButton />
            </navbar.Actions>
          </Navbar>
    
          <UserThreads />
        </div>
      
    */
    {
      "id": "1A34ztjZ",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"c-routes --threads\"],[12],[1,\"\\n      \"],[8,[32,0],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[30,1,[\"Title\"]],null,[[\"@title\"],[[28,[32,1],[\"chat.my_threads.title\"],null]]],null],[1,\"\\n\\n        \"],[8,[30,1,[\"Actions\"]],null,null,[[\"default\"],[[[[1,\"\\n          \"],[8,[30,2,[\"OpenDrawerButton\"]],null,null,null],[1,\"\\n        \"]],[2]]]]],[1,\"\\n      \"]],[1]]]]],[1,\"\\n\\n      \"],[8,[32,2],null,null,null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"navbar\",\"action\"],false,[]]",
      "moduleName": "/home/discourse/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/routes/threads.js",
      "scope": () => [_navbar.default, _discourseI18n.i18n, _userThreads.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatRoutesThreads;
});