define("discourse/plugins/chat/discourse/components/chat/list/index", ["exports", "@glimmer/component", "@ember/helper", "@ember/object", "ember-modifier", "discourse/components/conditional-loading-spinner", "discourse/lib/debounce", "discourse/lib/environment", "discourse/lib/is-element-in-viewport", "discourse/plugins/chat/discourse/components/chat/list/empty-state", "discourse/plugins/chat/discourse/components/chat/list/item", "@ember/component", "@ember/template-factory"], function (_exports, _component, _helper, _object, _emberModifier, _conditionalLoadingSpinner, _debounce, _environment, _isElementInViewport, _emptyState, _item, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class List extends _component.default {
    loadMore = (() => (0, _emberModifier.modifier)(element => {
      this.intersectionObserver = new IntersectionObserver(this.loadCollection);
      this.intersectionObserver.observe(element);
      return () => {
        this.intersectionObserver.disconnect();
      };
    }))();
    fill = (() => (0, _emberModifier.modifier)(element => {
      this.resizeObserver = new ResizeObserver(() => {
        if ((0, _isElementInViewport.default)(element)) {
          this.loadCollection();
        }
      });
      this.resizeObserver.observe(element);
      return () => {
        this.resizeObserver.disconnect();
      };
    }))();
    get itemComponent() {
      return this.args.itemComponent ?? _item.default;
    }
    loadCollection() {
      (0, _debounce.default)(this, this.debouncedLoadCollection, _environment.INPUT_DELAY);
    }
    static #_ = (() => dt7948.n(this.prototype, "loadCollection", [_object.action]))();
    async debouncedLoadCollection() {
      await this.args.collection.load({
        limit: 10
      });
    }
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="c-list">
          <div {{this.fill}} ...attributes>
            {{#each @collection.items as |item|}}
              {{yield (hash Item=(component this.itemComponent item=item))}}
            {{else}}
              {{#if @collection.fetchedOnce}}
                {{yield (hash EmptyState=EmptyState)}}
              {{/if}}
            {{/each}}
          </div>
    
          <div {{this.loadMore}}>
            <br />
          </div>
    
          <ConditionalLoadingSpinner @condition={{@collection.loading}} />
        </div>
      
    */
    {
      "id": "9U+oiNP0",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"c-list\"],[12],[1,\"\\n      \"],[11,0],[17,1],[4,[30,0,[\"fill\"]],null,null],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,2,[\"items\"]]],null]],null],null,[[[1,\"          \"],[18,4,[[28,[32,0],null,[[\"Item\"],[[50,[30,0,[\"itemComponent\"]],0,null,[[\"item\"],[[30,3]]]]]]]]],[1,\"\\n\"]],[3]],[[[41,[30,2,[\"fetchedOnce\"]],[[[1,\"            \"],[18,4,[[28,[32,0],null,[[\"EmptyState\"],[[32,1]]]]]],[1,\"\\n\"]],[]],null]],[]]],[1,\"      \"],[13],[1,\"\\n\\n      \"],[11,0],[4,[30,0,[\"loadMore\"]],null,null],[12],[1,\"\\n        \"],[10,\"br\"],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[8,[32,2],null,[[\"@condition\"],[[30,2,[\"loading\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"&attrs\",\"@collection\",\"item\",\"&default\"],false,[\"each\",\"-track-array\",\"yield\",\"component\",\"if\"]]",
      "moduleName": "/home/discourse/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/list/index.js",
      "scope": () => [_helper.hash, _emptyState.default, _conditionalLoadingSpinner.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = List;
});