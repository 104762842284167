define("discourse/plugins/chat/discourse/components/chat-message-separator", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/modifier", "@ember/object", "ember-modifier", "discourse/helpers/concat-class", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _modifier, _object, _emberModifier, _concatClass, _discourseI18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const IS_PINNED_CLASS = "is-pinned";
  class ChatMessageSeparator extends _component.default {
    track = (() => (0, _emberModifier.modifier)(element => {
      const intersectionObserver = new IntersectionObserver(_ref => {
        let [entry] = _ref;
        if (entry.isIntersecting && entry.intersectionRatio < 1 && entry.boundingClientRect.y < entry.intersectionRect.y) {
          entry.target.classList.add(IS_PINNED_CLASS);
        } else {
          entry.target.classList.remove(IS_PINNED_CLASS);
        }
      }, {
        threshold: [0, 1]
      });
      intersectionObserver.observe(element);
      return () => {
        intersectionObserver?.disconnect();
      };
    }))();
    onDateClick() {
      return this.args.fetchMessagesByDate?.(this.firstMessageOfTheDayAt);
    }
    static #_ = (() => dt7948.n(this.prototype, "onDateClick", [_object.action]))();
    get firstMessageOfTheDayAt() {
      const message = this.args.message;
      if (!message.previousMessage) {
        return this.#startOfDay(message.createdAt);
      }
      if (!this.#areDatesOnSameDay(message.previousMessage.createdAt, message.createdAt)) {
        return this.#startOfDay(message.createdAt);
      }
    }
    static #_2 = (() => dt7948.n(this.prototype, "firstMessageOfTheDayAt", [_tracking.cached]))();
    get formattedFirstMessageDate() {
      if (this.firstMessageOfTheDayAt) {
        return this.#calendarDate(this.firstMessageOfTheDayAt);
      }
    }
    static #_3 = (() => dt7948.n(this.prototype, "formattedFirstMessageDate", [_tracking.cached]))();
    get isNewestMessage() {
      return this.args.message.id === this.args.message.channel.newestMessage?.id;
    }
    #areDatesOnSameDay(a, b) {
      return a.getFullYear() === b.getFullYear() && a.getMonth() === b.getMonth() && a.getDate() === b.getDate();
    }
    #startOfDay(date) {
      return moment(date).startOf("day").format();
    }
    #calendarDate(date) {
      return moment(date).calendar(moment(), {
        sameDay: `[${(0, _discourseI18n.i18n)("chat.chat_message_separator.today")}]`,
        lastDay: `[${(0, _discourseI18n.i18n)("chat.chat_message_separator.yesterday")}]`,
        lastWeek: "LL",
        sameElse: "LL"
      });
    }
    static #_4 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.formattedFirstMessageDate}}
          <div
            class={{concatClass
              "chat-message-separator"
              "chat-message-separator-date"
              (if this.isNewestMessage "with-last-visit")
            }}
            role="button"
            {{on "click" this.onDateClick passive=true}}
            data-id={{@message.id}}
          >
            <div class="chat-message-separator__text-container" {{this.track}}>
              <span class="chat-message-separator__text">
                {{this.formattedFirstMessageDate}}
    
                {{#if this.isNewestMessage}}
                  <span class="chat-message-separator__last-visit">
                    <span
                      class="chat-message-separator__last-visit-separator"
                    >-</span>
                    {{i18n "chat.last_visit"}}
                  </span>
                {{/if}}
              </span>
            </div>
          </div>
    
          <div class="chat-message-separator__line-container">
            <div class="chat-message-separator__line"></div>
          </div>
        {{else if this.isNewestMessage}}
          <div
            class="chat-message-separator chat-message-separator-new"
            data-id={{@message.id}}
          >
            <div class="chat-message-separator__text-container">
              <span class="chat-message-separator__text">
                {{i18n "chat.last_visit"}}
              </span>
            </div>
    
            <div class="chat-message-separator__line-container">
              <div class="chat-message-separator__line"></div>
            </div>
          </div>
        {{/if}}
      
    */
    {
      "id": "MBddcJAV",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"formattedFirstMessageDate\"]],[[[1,\"      \"],[11,0],[16,0,[28,[32,0],[\"chat-message-separator\",\"chat-message-separator-date\",[52,[30,0,[\"isNewestMessage\"]],\"with-last-visit\"]],null]],[24,\"role\",\"button\"],[16,\"data-id\",[30,1,[\"id\"]]],[4,[32,1],[\"click\",[30,0,[\"onDateClick\"]]],[[\"passive\"],[true]]],[12],[1,\"\\n        \"],[11,0],[24,0,\"chat-message-separator__text-container\"],[4,[30,0,[\"track\"]],null,null],[12],[1,\"\\n          \"],[10,1],[14,0,\"chat-message-separator__text\"],[12],[1,\"\\n            \"],[1,[30,0,[\"formattedFirstMessageDate\"]]],[1,\"\\n\\n\"],[41,[30,0,[\"isNewestMessage\"]],[[[1,\"              \"],[10,1],[14,0,\"chat-message-separator__last-visit\"],[12],[1,\"\\n                \"],[10,1],[14,0,\"chat-message-separator__last-visit-separator\"],[12],[1,\"-\"],[13],[1,\"\\n                \"],[1,[28,[32,2],[\"chat.last_visit\"],null]],[1,\"\\n              \"],[13],[1,\"\\n\"]],[]],null],[1,\"          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[10,0],[14,0,\"chat-message-separator__line-container\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"chat-message-separator__line\"],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],[[[41,[30,0,[\"isNewestMessage\"]],[[[1,\"      \"],[10,0],[14,0,\"chat-message-separator chat-message-separator-new\"],[15,\"data-id\",[30,1,[\"id\"]]],[12],[1,\"\\n        \"],[10,0],[14,0,\"chat-message-separator__text-container\"],[12],[1,\"\\n          \"],[10,1],[14,0,\"chat-message-separator__text\"],[12],[1,\"\\n            \"],[1,[28,[32,2],[\"chat.last_visit\"],null]],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[10,0],[14,0,\"chat-message-separator__line-container\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"chat-message-separator__line\"],[12],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[]],null]],[]]],[1,\"  \"]],[\"@message\"],false,[\"if\"]]",
      "moduleName": "/home/discourse/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-message-separator.js",
      "scope": () => [_concatClass.default, _modifier.on, _discourseI18n.i18n],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatMessageSeparator;
});