define("discourse/plugins/chat/discourse/components/chat/modal/move-message-to-channel", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "@ember/template", "@ember/utils", "discourse/components/d-button", "discourse/components/d-modal", "discourse/lib/ajax-error", "discourse-i18n", "discourse/plugins/chat/discourse/components/chat-channel-chooser", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _service, _template, _utils, _dButton, _dModal, _ajaxError, _discourseI18n, _chatChannelChooser, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatModalMoveMessageToChannel extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "chat", [_service.service]))();
    #chat = (() => (dt7948.i(this, "chat"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "chatApi", [_service.service]))();
    #chatApi = (() => (dt7948.i(this, "chatApi"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "chatChannelsManager", [_service.service]))();
    #chatChannelsManager = (() => (dt7948.i(this, "chatChannelsManager"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "destinationChannelId", [_tracking.tracked]))();
    #destinationChannelId = (() => (dt7948.i(this, "destinationChannelId"), void 0))();
    get sourceChannel() {
      return this.args.model.sourceChannel;
    }
    get selectedMessageIds() {
      return this.args.model.selectedMessageIds;
    }
    get selectedMessageCount() {
      return this.selectedMessageIds?.length;
    }
    get disableMoveButton() {
      return (0, _utils.isBlank)(this.destinationChannelId);
    }
    get availableChannels() {
      return (this.args.model.availableChannels || this.chatChannelsManager.publicMessageChannels).rejectBy("id", this.sourceChannel.id);
    }
    get instructionsText() {
      return (0, _template.htmlSafe)((0, _discourseI18n.i18n)("chat.move_to_channel.instructions", {
        channelTitle: this.sourceChannel.escapedTitle,
        count: this.selectedMessageCount
      }));
    }
    moveMessages() {
      return this.chatApi.moveChannelMessages(this.sourceChannel.id, {
        message_ids: this.selectedMessageIds,
        destination_channel_id: this.destinationChannelId
      }).then(response => {
        this.args.closeModal();
        this.router.transitionTo("chat.channel.near-message", "-", response.destination_channel_id, response.first_moved_message_id);
      }).catch(_ajaxError.popupAjaxError);
    }
    static #_6 = (() => dt7948.n(this.prototype, "moveMessages", [_object.action]))();
    static #_7 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DModal
          @closeModal={{@closeModal}}
          class="chat-modal-move-message-to-channel"
          @inline={{@inline}}
          @title={{i18n "chat.move_to_channel.title"}}
        >
          <:body>
            {{#if this.selectedMessageCount}}
              <p>{{this.instructionsText}}</p>
            {{/if}}
    
            <ChatChannelChooser
              @content={{this.availableChannels}}
              @value={{this.destinationChannelId}}
              @nameProperty="title"
              class="chat-modal-move-message-to-channel__channel-chooser"
            />
          </:body>
          <:footer>
            <DButton
              @icon="right-from-bracket"
              @disabled={{this.disableMoveButton}}
              @action={{this.moveMessages}}
              @label="chat.move_to_channel.confirm_move"
              class="btn-primary"
            />
            <DButton @label="cancel" @action={{@closeModal}} class="btn-flat" />
          </:footer>
        </DModal>
      
    */
    {
      "id": "zTCw6DlK",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"chat-modal-move-message-to-channel\"]],[[\"@closeModal\",\"@inline\",\"@title\"],[[30,1],[30,2],[28,[32,1],[\"chat.move_to_channel.title\"],null]]],[[\"body\",\"footer\"],[[[[1,\"\\n\"],[41,[30,0,[\"selectedMessageCount\"]],[[[1,\"          \"],[10,2],[12],[1,[30,0,[\"instructionsText\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"\\n        \"],[8,[32,2],[[24,0,\"chat-modal-move-message-to-channel__channel-chooser\"]],[[\"@content\",\"@value\",\"@nameProperty\"],[[30,0,[\"availableChannels\"]],[30,0,[\"destinationChannelId\"]],\"title\"]],null],[1,\"\\n      \"]],[]],[[[1,\"\\n        \"],[8,[32,3],[[24,0,\"btn-primary\"]],[[\"@icon\",\"@disabled\",\"@action\",\"@label\"],[\"right-from-bracket\",[30,0,[\"disableMoveButton\"]],[30,0,[\"moveMessages\"]],\"chat.move_to_channel.confirm_move\"]],null],[1,\"\\n        \"],[8,[32,3],[[24,0,\"btn-flat\"]],[[\"@label\",\"@action\"],[\"cancel\",[30,1]]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n  \"]],[\"@closeModal\",\"@inline\"],false,[\"if\"]]",
      "moduleName": "/home/discourse/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/modal/move-message-to-channel.js",
      "scope": () => [_dModal.default, _discourseI18n.i18n, _chatChannelChooser.default, _dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatModalMoveMessageToChannel;
});