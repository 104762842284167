define("discourse/plugins/chat/discourse/components/chat-channel-title", ["exports", "discourse/plugins/chat/discourse/components/channel-title", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _channelTitle, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const OldChatChannelTitle = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <ChannelTitle @channel={{@channel}} />
  
  */
  {
    "id": "nFKCc1L4",
    "block": "[[[1,\"\\n  \"],[8,[32,0],null,[[\"@channel\"],[[30,1]]],null],[1,\"\\n\"]],[\"@channel\"],false,[]]",
    "moduleName": "/home/discourse/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-channel-title.js",
    "scope": () => [_channelTitle.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "chat-channel-title:OldChatChannelTitle"));
  var _default = _exports.default = OldChatChannelTitle;
});